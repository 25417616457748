import React from 'react';
import ScrollNext from '../ScrollNext';

const ExpertiseSection = () => {
    return (
        <section
            id="expertise"
            className="relative flex flex-col items-center justify-center min-h-screen bg-black text-white py-16 px-6"
        >
            {/* Section Heading */}
            <div className="text-center mb-12 z-10">
                <h2 className="text-4xl md:text-6xl lg:text-8xl font-extrabold tracking-tight">
                    My Expertise
                </h2>
                <p className="mt-4 text-lg md:text-xl text-gray-300">
                    Crafting high-performance applications with modern technologies.
                </p>
            </div>

            {/* Expertise Cards */}
            <div className="flex flex-col sm:flex-row justify-center items-stretch sm:space-x-6 max-w-screen-lg z-10 space-y-8 sm:space-y-0 p-5">

                {/* Software Development */}
                <div className="border border-gray-700 p-8 rounded-lg text-left w-full sm:w-1/3 flex flex-col min-h-full bg-[#1A1A1A] hover:shadow-lg hover:shadow-pink-500/50 transition">
                    <h3 className="text-xl font-bold">
                        Backend <span className="underline decoration-pink-500">Development</span>
                    </h3>
                    <p className="mt-4 text-gray-400 flex-grow">
                        Building robust, scalable, and maintainable software solutions with expertise in both functional and OOP paradigms. Skilled in PHP, Elixir, JavaScript/TypeScript, and Python for backend development and scripting.
                    </p>
                </div>

                {/* Frontend Development */}
                <div className="border border-gray-700 p-8 rounded-lg text-left w-full sm:w-1/3 flex flex-col min-h-full bg-[#1A1A1A] hover:shadow-lg hover:shadow-blue-500/50 transition">
                    <h3 className="text-xl font-bold">
                        Frontend <span className="underline decoration-blue-500">Development</span>
                    </h3>
                    <p className="mt-4 text-gray-400 flex-grow">
                        Creating engaging and high-performance user interfaces using React, Vue, and Angular. Experienced in HTML, CSS, JavaScript, and modern front-end architecture.
                    </p>
                </div>

                {/* React Native Development */}
                <div className="border border-gray-700 p-8 rounded-lg text-left w-full sm:w-1/3 flex flex-col min-h-full bg-[#1A1A1A] hover:shadow-lg hover:shadow-orange-500/50 transition">
                    <h3 className="text-xl font-bold">
                        React Native <span className="underline decoration-orange-500">Development</span>
                    </h3>
                    <p className="mt-4 text-gray-400 flex-grow">
                        Designing and developing cross-platform mobile applications for iOS and Android using React Native. Focused on performance and seamless user experience.
                    </p>
                </div>
            </div>

            {/* Background Image */}
            <div className="absolute inset-0 flex justify-center items-center opacity-20">
                <img
                    src="/expertise.png"
                    alt="Expertise Background"
                    className="w-full h-auto max-w-3xl md:max-w-md lg:max-w-lg rounded-lg shadow-2xl"
                />
            </div>

            {/* Scroll to Next Section */}
            <ScrollNext to="work" className="hidden sm:block" />
        </section>
    );
};

export default ExpertiseSection;
